import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios";

export const fetchDevices = createAsyncThunk('devices/fetchDevices', async () => {
    const { data } = await axios.get('/devices');
    return data;
});

export const fetchRemoveDevice = createAsyncThunk('devices/fetchRemoveDevice', async (id) => {
    await axios.delete(`/devices/${id}`);
});


const initialState = {
    items: [],
    status: 'loading',
    error: null,
};

const devicesSlice = createSlice({
    name: 'devices',
    initialState,
    reducers: {
        online: (state, action) => {
            state.items = state.items.map((obj) => {
                if (obj._id === action.payload) {
                    obj.online = true;
                }
                return obj;
            });
        
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDevices.pending, (state) => {
                state.status = 'loading';
                state.items = [];
            })
            .addCase(fetchDevices.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.items = action.payload;
            })
            .addCase(fetchDevices.rejected, (state) => {
                state.status = 'error';
                state.items = [];
            })
            .addCase(fetchRemoveDevice.pending, (state, action) => {
                state.items = state.items.filter((obj) => obj._id !== action.meta.arg);
            })
    }
});

export const devicesReducer = devicesSlice.reducer;