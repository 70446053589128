import React from "react";
import { useSelector } from "react-redux";
import { useNavigate,  Navigate, useParams } from "react-router-dom";

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
// import { useForm } from "react-hook-form";

import styles from "./AddDevice.module.scss";
import { selectIsAuth } from "../../redux/slices/auth";
import axios from "../../axios"

export const AddDevice = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const isAuth = useSelector(selectIsAuth);
  // const [isLoading, setLoading] = React.useState(false);
  const [name, setName] = React.useState('');
  const [serialNumber, setSerialNumber] = React.useState('');

  const isEditing = Boolean(id);

  React.useEffect(() => {
    if (id) {
      axios.get(`/devices/${id}`)
        .then(res =>{
          setName(res.data.name);
          setSerialNumber(res.data.serialNumber);
        })
        .catch((err) => {
          console.warn(err);
          alert(`Помилка при завантажені даних. Error: ${err.message}`);
        })
    }
      
    }, [id]);

  // const dispatch = useDispatch();
  // const { 
  //   register, 
  //   handleSubmit, 
  //   formState: { errors, isValid } 
  // } = useForm({
  //   defaultValues: {
  //     name: '',
  //     serialNumber: '123456',
  //   },
  //   mode: 'onChange',
  // });
  
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const fields = {
        name,
        serialNumber,
      };

      const { data } = isEditing
        ? await axios.patch(`/devices/${id}`, fields)
        : await axios.post('/devices', fields);
      console.log(data);
      navigate('/');
    } catch (error) {
      console.warn(error);
      alert(error.message);
    } 
    
  };

  if (!window.localStorage.getItem('token') && !window.sessionStorage.getItem('token') &&  !isAuth) {
    return <Navigate to="/" />;
  }

  return (
    <Paper elevation={0} classes={{ root: styles.root }}>
      <Typography classes={{ root: styles.title }} variant="h5">
        {isEditing ? 'Редагування пристрою' : 'Додавання пристрою'}
      </Typography>
      <form onSubmit={onSubmit}>
        <TextField
          className={styles.field}
          label="Назва"
          value={name}
          onChange={(e) => setName(e.target.value)}
          // error={Boolean(errors.email?.message)}
          // helperText={errors.email?.message}
          // {...register("email", { required: 'Вкажіть пошту' })}
          fullWidth
        />
        <TextField 
          className={styles.field} 
          label="SerialNumder" 
          value={serialNumber}
          onChange={(e) => setSerialNumber(e.target.value)}
          // error={Boolean(errors.password?.message)}
          // helperText={errors.password?.message}
          // {...register("password", { required: 'Вкажіть пароль' })}
          fullWidth />
        <Button type="submit" size="large" variant="contained" fullWidth>
          {isEditing ? 'Зберегти' : 'Надіслати'}
        </Button>
      </form>
    </Paper>
  );
};
