import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios";

export const fetchChanels = createAsyncThunk('chanels/fetchChanels', async () => {
    const { data } = await axios.get('/chanels');
    return data;
});

export const fetchRemoveChanel = createAsyncThunk('chanels/fetchRemoveChanel', async (id) => {
    await axios.delete(`/chanels/${id}`);
});


const initialState = {
    items: [],
    status: 'loading',
    error: null,
};

const chanelsSlice = createSlice({
    name: 'chanels',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchChanels.pending, (state) => {
                state.status = 'loading';
                state.items = [];
            })
            .addCase(fetchChanels.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.items = action.payload;
            })
            .addCase(fetchChanels.rejected, (state) => {
                state.status = 'error';
                state.items = [];
            })
            .addCase(fetchRemoveChanel.pending, (state, action) => {
                state.items = state.items.filter((obj) => obj._id !== action.meta.arg);
            })
    }
});

export const chanelsReducer = chanelsSlice.reducer;