import React, { useState, useEffect } from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import { Button, Box } from "@mui/material";
import { Device } from '../components/Device';
import { Chanel } from '../components/Chanel';
import { fetchDevices } from '../redux/slices/devices';
import { fetchChanels } from '../redux/slices/chanels';
import { connect } from "mqtt";

const client = connect(process.env.REACT_APP_MQTT_SERVER, {
  port: process.env.REACT_APP_MQTT_PORT,
  username: process.env.REACT_APP_MQTT_USERNAME,
  password: process.env.REACT_APP_MQTT_PASSWORD,
  keepalive: 10000,
});

const buttonStyle = {
  width: "100%",
  marginBottom: "8px",
  padding: "8px",
  transition: "background-color 0.3s ease",
};

export const Home = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.data);
  const devices = useSelector((state) => state.devices);
  const chanels = useSelector((state) => state.chanels);
  const isDevicesLoading = devices.status === 'loading';
  const isChanelsLoading = chanels.status === 'loading';

  const [deviceStatuses, setDeviceStatuses] = useState({});
  const [subscribedDevices, setSubscribedDevices] = useState([]);

  const [isTrivogaActive, setIsTrivogaActive] = useState(false);
  const [isVidbiyActive, setIsVidbiyActive] = useState(false);

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };;

  useEffect(() => {
    const messageHandler = (topic, message) => {
      const serialNumber = topic.split('/')[1];
      const newDeviceStatuses = { ...deviceStatuses, [serialNumber]: message.toString() };
      setDeviceStatuses(newDeviceStatuses);

      if (topic === `${process.env.REACT_APP_MQTT_TOPIC}/command`) {
        const command = message.toString();
  
        if (command === 'trivogaStart') {
          setIsTrivogaActive(true);
          setIsVidbiyActive(false);
        } else if (command === 'vidbiyStart') {
          setIsTrivogaActive(false);
          setIsVidbiyActive(true);
        } else if (command === 'stop') {
          setIsTrivogaActive(false);
          setIsVidbiyActive(false);
        }
      }
    };
  
    devices.items.forEach((obj) => {
      const topic = `${process.env.REACT_APP_MQTT_TOPIC}/${obj.serialNumber}/status`;
      if (!subscribedDevices.includes(topic)) {
        client.subscribe(topic);
        setSubscribedDevices([...subscribedDevices, topic]);
      }
    });

    client.subscribe(`${process.env.REACT_APP_MQTT_TOPIC}/command`);
  
    client.on('message', messageHandler);

    return () => {
      client.unsubscribe(`${process.env.REACT_APP_MQTT_TOPIC}/command`);
      client.removeListener('message', messageHandler);
    };
    
  }, [devices.items, subscribedDevices, deviceStatuses]); // Додали deviceStatuses у залежності

  useEffect(() => {
    dispatch(fetchDevices());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchChanels());
  }, [dispatch]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      client.publish(`${process.env.REACT_APP_MQTT_TOPIC}/command`, 'getStatus');
      client.publish(`${process.env.REACT_APP_MQTT_TOPIC}/NewCommand`, '{cmd: "about"}');
    
    }, 1000); // Затримка в мілісекундах (у цьому прикладі - 1 секунда)
  
    return () => {
      clearTimeout(timer); // Очищення таймера при зміні залежностей або розмонтуванні компонента
    };
  }, []);

  const trivogaStart = () => {
    setIsTrivogaActive(true);
    setIsVidbiyActive(false);
    client.publish(`${process.env.REACT_APP_MQTT_TOPIC}/command`, 'trivogaStart');
    client.publish(`${process.env.REACT_APP_MQTT_TOPIC}/NewCommand`, `{"mp3":"${process.env.REACT_APP_FILE_TRIVOGA}"}`);
    
  }

  const vidbiyStart = () => {
    setIsTrivogaActive(false);
    setIsVidbiyActive(true);
    client.publish(`${process.env.REACT_APP_MQTT_TOPIC}/command`, 'vidbiyStart');
    client.publish(`${process.env.REACT_APP_MQTT_TOPIC}/NewCommand`, `{"mp3":"${process.env.REACT_APP_FILE_VIDBIY}"}`);
  }

  const stop = () => {
    setIsTrivogaActive(false);
    setIsVidbiyActive(false);
    client.publish(`${process.env.REACT_APP_MQTT_TOPIC}/command`, 'stop');
    client.publish(`${process.env.REACT_APP_MQTT_TOPIC}/NewCommand`, '{cmd: "break"}');
  }

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>        
        <TabContext value={value}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Пристрої" value="1" />
            <Tab label="ТГ Канали" value="2" />
          </TabList>

        
          <Grid container spacing={4}>        
            <Grid xs={8} item>
              <TabPanel value="1">
                {(isDevicesLoading ? [...Array(5)] : devices.items).map((obj, index) =>
                  isDevicesLoading ? (
                    <Device key={index} isLoading={true} />
                  ) : (
                    <React.Fragment key={obj._id}>
                      <Device
                        id={obj._id}
                        key={obj._id}
                        name={obj.name}
                        serialNumber={obj.serialNumber}
                        user={obj.user}
                        createdAt={obj.createdAt}
                        isEditable={userData?.userData._id === obj.user._id}
                        status={deviceStatuses[obj.serialNumber]}
                      />
                    </React.Fragment>
                  )
                )}
              </TabPanel>
              <TabPanel value="2">              
                  {(isChanelsLoading ? [...Array(5)] : chanels.items).map((obj, index) =>
                  isChanelsLoading ? (
                    <Chanel key={index} isLoading={true} />
                  ) : (
                    <React.Fragment key={obj._id}>
                      <Chanel
                        id={obj._id}
                        key={obj._id}
                        name={obj.name}
                        chanelId={obj.chanelId}
                        user={obj.user}
                        createdAt={obj.createdAt}
                        isEditable={userData?.userData._id === obj.user._id}
                      />
                    </React.Fragment>
                  )
                )}
              </TabPanel>
            </Grid>
            
            

            <Grid xs={4} item>
              <Box textAlign="center">
                <Button
                  disabled={isTrivogaActive}
                  onClick={trivogaStart}
                  variant="contained"
                  sx={{ ...buttonStyle, backgroundColor: "#dc3545", "&:hover": { backgroundColor: "#bb2d3b" } }}
                >
                  Тривога
                </Button>
                <Button
                  disabled={isVidbiyActive}
                  onClick={vidbiyStart}
                  variant="contained"
                  sx={{ ...buttonStyle, color: "#000", backgroundColor: "#ffca2c", "&:hover": { backgroundColor: "#ffc107" } }}
                >
                  Відбій
                </Button>
                <Button
                  onClick={stop}
                  variant="contained"
                  sx={{ ...buttonStyle, backgroundColor: "#0d6efd", "&:hover": { backgroundColor: "#0a58ca" } }}
                >
                  Стоп
                </Button>
              </Box>
            </Grid>
          </Grid>
        </TabContext>
      </Box>

    </>
  );
};
