import { configureStore } from "@reduxjs/toolkit";
import { devicesReducer } from "./slices/devices";
import { chanelsReducer } from "./slices/chanels";
import { authReducer } from "./slices/auth";

const store = configureStore({
    reducer: {
        devices: devicesReducer,
        chanels: chanelsReducer,
        auth: authReducer,
    }
});

export default store;