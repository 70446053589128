import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

import styles from './Header.module.scss';
import Container from '@mui/material/Container';
import { logout, selectIsAuth } from "../../redux/slices/auth";

import { createTheme, ThemeProvider } from '@mui/material/styles';

const themeTelegram = createTheme({
  palette: {
    ochre: {
      main: ' #179CDE',
      light: '#229ED9',
      dark: '#0088CC',
      contrastText: '#FFFFFF',
    },
  },
});

export const Header = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector(selectIsAuth);

  const onClickLogout = () =>  {
    if (window.confirm('Дійсно вийти?')) {
      dispatch(logout())
      window.localStorage.removeItem('token');
    }
  };

  return (
    <div className={styles.root}>
      <Container maxWidth="lg">
        <div className={styles.inner}>
          <Link className={styles.logo} to="/">
            <div>IOT SERVICE</div>
          </Link>
          <div className={styles.buttons}>
            {isAuth ? (
              <>
               <ThemeProvider theme={themeTelegram}>
                  <Link to="/add-device">
                    <Button variant="contained">Додати пристрій</Button>
                  </Link>
                  <Link to="/add-chanel">
                    <Button color="ochre" variant="contained">Додати ТГ канал</Button>
                  </Link>
                  <Button onClick={onClickLogout} variant="contained" color="error">
                    Вийти
                  </Button>
                </ThemeProvider>
              </>
            ) : (
              <>
                <Link to="/login">
                  <Button variant="outlined">Увійти</Button>
                </Link>
                <Link to="/register">
                  <Button variant="contained">Створити аккаунт</Button>
                </Link>
              </>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};
