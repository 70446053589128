import Container from "@mui/material/Container";
import { Routes, Route } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
import { useSelector, useDispatch } from "react-redux";

import { Header } from "./components";
import { Home, /*Registration,*/ AddDevice, AddChanel, Login } from "./pages";
import React from 'react';
// import { fetchAuthMe, selectIsAuth } from "./redux/slices/auth";
import { fetchAuthMe, selectIsAuth } from "./redux/slices/auth";


function App() {
  const dispatch = useDispatch();
  const isAuth = useSelector(selectIsAuth);
  // const isAuth = useSelector(selectIsAuth);

  React.useEffect(() => {
    dispatch(fetchAuthMe());
  }, [dispatch]);

  return (
    <>
      <Header />
      <Container maxWidth="lg">
        <Routes>
          <Route path="/" element={isAuth ? <Home /> : <Login />} />
          <Route path="/add-device" element={<AddDevice />} />
          <Route path="/add-chanel" element={<AddChanel />} />
          <Route path="/login" element={<Login />} />
          {/* <Route path="/register" element={<Registration />} /> */}
          <Route path="/devices/:id/edit" element={<AddDevice />} />
          <Route path="/chanels/:id/edit" element={<AddChanel />} />


          {/* <Home /> */}
          {/*<FullPost />*/}
          {/*<AddPost />*/}
          {/*<Login />*/}
          {/*<Registration />*/}
        </Routes>
      </Container>
    </>
  );
}

export default App;
